<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('profile')"
                class="mr-4"
                style="cursor: pointer"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon>mdi-account-box</v-icon>
              Editar Perfil
            </v-card-title>
          </v-card>
        </v-col>
        <v-form
          ref="formProfile"
          v-model="formIsValid"
          v-on:submit.prevent="submitEditProfile()"
        >
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.cnpj"
                      outlined
                      label="CNPJ"
                      v-mask="['##.###.###/####-##']"
                      disabled
                    />
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.companyName"
                      :rules="textRules"
                      outlined
                      label="Nome da empresa"
                    />
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.fantasyName"
                      :rules="textRules"
                      outlined
                      label="Nome fantasia"
                    />
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.phone"
                      outlined
                      label="Telefone"
                      :rules="telRules"
                      v-mask="['(##) ####-####', '(##) # ####-####']"
                    />
                  </v-col>
                  <v-col md="5" sm="12">
                    <v-text-field
                      v-model="profile.email"
                      :rules="emailRules"
                      outlined
                      type="email"
                      label="Email"
                    />
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-select
                      v-model="profile.state"
                      :items="siglas"
                      label="Estado"
                      placeholder="Selecione um Estado"
                      :rules="textRules"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.city"
                      :rules="textRules"
                      outlined
                      label="Cidade"
                    />
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.district"
                      outlined
                      label="Bairro"
                    />
                  </v-col>
                  <v-col md="4" sm="12">
                    <v-text-field
                      v-model="profile.street"
                      :rules="textRules"
                      outlined
                      label="Rua"
                    />
                  </v-col>
                  <v-col md="2" sm="12">
                    <v-text-field
                      v-model.number="profile.number"
                      :rules="textRules"
                      outlined
                      label="Número"
                      type="number"
                    />
                  </v-col>
                  <v-col md="4" sm="12">
                    <v-text-field
                      v-model="profile.addressComplement"
                      outlined
                      label="Complemento"
                    />
                  </v-col>
                  <v-col md="3" sm="12">
                    <v-text-field
                      v-model="profile.zipcode"
                      :rules="textRules"
                      outlined
                      label="Cep"
                      v-mask="['#####-###']"
                    />
                  </v-col>
                </v-row>

                <!-- <v-row
                  v-for="(weekDay, index) in profile.weekDays"
                  :key="index + 'wk'"
                >
                  <v-col xl="3" lg="3" md="3" sm="4">
                    <v-select
                      v-model="weekDay.id"
                      :items="weekDays"
                      item-value="id"
                      item-text="name"
                      label="Dia da semana"
                      placeholder="Escolha um dia da semana"
                      :rules="textRules"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col xl="3" lg="3" md="3" sm="4">
                    <v-text-field
                      label="Início"
                      append-icon="mdi-clock-time-four-outline"
                      v-model="weekDay.startTime"
                      placeholder="Horário de abertura"
                      v-mask="['##:##']"
                      :rules="hourRules"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col xl="3" lg="3" md="3" sm="4">
                    <v-text-field
                      label="Fim"
                      append-icon="mdi-clock-time-seven-outline"
                      v-model="weekDay.endTime"
                      placeholder="Horário de fechamento"
                      v-mask="['##:##']"
                      :rules="hourRules"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col xl="2" lg="2" md="2" sm="2" class="justify-center">
                    <v-tooltip bottom v-if="1 != profile.weekDays.length">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="orange"
                          v-bind="attrs"
                          v-on="on"
                          class="mt-3"
                          v-on:click="removeWeekDay(index)"
                        >
                          <v-icon>mdi-minus-circle </v-icon>
                        </v-btn>
                      </template>
                      <span>Remover Dia de trabalho</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="index == profile.weekDays.length - 1"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="green"
                          v-bind="attrs"
                          v-on="on"
                          class="mt-3"
                          v-on:click="addWeekDay()"
                        >
                          <v-icon>mdi-plus-circle </v-icon>
                        </v-btn>
                      </template>
                      <span>Adicionar Dia de trabalho</span>
                    </v-tooltip>
                  </v-col>
                </v-row> -->
                <v-row>
                  <!-- <v-col md="1 text-right">
                      <v-btn @click="resetForm()" color="error">
                          <v-icon left>
                              mdi-close-thick
                          </v-icon>
                          Limpar
                      </v-btn>
                  </v-col> -->
                  <v-col md="12" class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      style="color: #fff; background-color: rgb(61, 17, 89)"
                    >
                      <v-icon left>
                        mdi-check
                      </v-icon>
                      Enviar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-form>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import store from "@/store";

import { PROFILE_GET, PROFILE_UPDATE } from "@/store/actions/profile.type";
import {
  // PROFILE_REMOVE_WEEKDAY,
  // PROFILE_ADD_WEEKDAY,
  PROFILE_RESET_STATE
} from "@/store/mutations/profile.type";
// import { WEEKDAYS_GET } from "@/store/actions/weekday.type";
import { CHANGE_PROGRESS } from "@/store/mutations/mutations.type";

export default {
  name: "EditProfile",
  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    await store.commit(PROFILE_RESET_STATE);
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    await store.commit(PROFILE_RESET_STATE);
    await store.dispatch(PROFILE_GET);
    // await store.dispatch(WEEKDAYS_GET);
    //todo weekdays nunca muda, carregar uma vez ao logar.

    return next();
  },
  directives: { mask },

  data: () => ({
    formIsValid: false,
    siglas: [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MS",
      "MT",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO"
    ],
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    emailRules: [
      v => !!v || "E-mail é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Informe um email válido";
          }
        }
        return false;
      }
    ],
    hourRules: [
      v => !!v || "Campo obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/;
            return pattern.test(value) || "Informe um horário válido";
          }
        }
        return false;
      }
    ],
    telRules: [
      v => !!v || "Telefone é obrigatório",
      value => {
        if (typeof value !== "undefined" && value !== null) {
          if (value.length > 0) {
            const pattern = /(\(?\d{2}\)?\s)?(\d{4,5}-\d{4})$/;
            return pattern.test(value) || "Informe um telefone válido";
          }
        }
        return false;
      }
    ]
  }),

  methods: {
    // addWeekDay() {
    //     this.$store.commit(PROFILE_ADD_WEEKDAY);
    // },
    // removeWeekDay(index) {
    //     if (this.profile.weekDays.length > 1)
    //         this.$store.commit(PROFILE_REMOVE_WEEKDAY, index);
    // },

    submitEditProfile() {
      if (this.$refs.formProfile.validate()) {
        this.$store.commit(CHANGE_PROGRESS, true);

        this.$store
          .dispatch(PROFILE_UPDATE)
          .then(() => {
            this.$store.commit(CHANGE_PROGRESS, false);

            this.snackbar.snackbar = true;
            this.snackbar.color = "success";
            this.snackbar.text = "Salvo com sucesso";

            this.$router.push({ name: "profile" });
          })
          .catch(({ response }) => {
            this.$store.commit(CHANGE_PROGRESS, false);
            this.snackbar.bloqueiaEnvio = false;
            this.snackbar.snackbar = true;
            this.snackbar.color = "error";
            this.snackbar.text = "Erro ao salvar Perfil.";
            if (typeof response.data.message !== "undefined") {
              this.snackbar.text =
                "Erro ao salvar Perfil: " + response.data.message;
            }
          });
      } else {
        this.snackbar.snackbar = true;
        this.snackbar.color = "error";
        this.snackbar.text = "Preencha o formulário corretamente";
      }
    },

    pushRouteToView(route) {
      this.$router.push({ name: route });
    },

    resetForm() {
      this.$refs.formProfile.resetValidation();
    }
  },
  computed: {
    ...mapGetters(["profile", "snackbar", "inProgress"])
  }
};
</script>
